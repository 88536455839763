import React, { useState, useEffect, useRef } from 'react';
import ContextToolBar from '../ContextToolBar/ContextToolBar';
import ImageUploader from '../ImageUploader/ImageUpoader';
import Canvas from './Canvas'; // Child component

function CanvasContainer({
  svgContent, selectedTool, selectedColor,
  setSelectedColor, selectedNomexColor, setSelectedNomexColor,
  showContextToolBar, setShowContextToolBar, setSelectedTool,
  canvasState, setCanvasState, setEnvelopeDimension,
  defaultColor, defaultNomexColor, history, setHistory,
  redoStack, setRedoStack, canvasRef, viewMode,
  scenes, selectedScene, setSelectedScene, selectedFabric, paraventSize
}) {
  // const [canvasDimensions, setCanvasDimensions] = useState({ width: 720.8, height: 323.23 });
  const [svgDimensions, setSvgDimensions] = useState(null);
  const [showImageUploader, setShowImageUploader] = useState(false)
  // const [parsedSvgContent, setParsedSvgContent] = useState(parse(svgContent).children[0]);
  const containerRef = useRef(null);

  // const handleResize = () => {
  //   if (containerRef.current && svgContent) {
  //     // Extract width and height from viewBox
  //     const viewBoxValues = svgContent.properties.viewBox.split(' ');

  //     if (viewBoxValues && viewBoxValues.length >
  //       2) {
  //       const svgWidth = parseFloat(viewBoxValues[2]);
  //       const svgHeight = parseFloat(viewBoxValues[3]);
  //       const parentWidth = containerRef.current.offsetWidth;
  //       const parentHeight = containerRef.current.offsetHeight;
  //       let calculatedWidth = parentWidth;
  //       let calculatedHeight = parentHeight;
  //       setSvgDimensions({
  //         width: svgWidth,
  //         height: svgHeight
  //       });
  //       setCanvasDimensions({
  //         width: calculatedWidth,
  //         height: calculatedHeight
  //       });
  //     }
  //   }
  // };

  const handleCloseModal = () => {
    setShowImageUploader(false);
  };

  useEffect(() => {
    const viewBoxValues = svgContent.properties.viewBox.split(' ');
    setSvgDimensions(
      {
        width: viewBoxValues[2],
        height: viewBoxValues[3]
      }
    )
  }, [])


  // useEffect(() => {
  //   handleResize(); // Initial sizing
  //   window.addEventListener('resize', handleResize);

  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  // useEffect(() => {
  //   handleResize()
  // }, [viewMode])


  return (
    <div ref={containerRef}
      // className='canvas-container'
      style={{
        // aspectRatio: svgDimensions.width / svgDimensions.height,
        // width: 'min(100%, min(100%, 40vh *' + svgDimensions.width / svgDimensions.height + '))',
        width: 'fit-content',
        display: viewMode === "Envelope" ? 'none' : 'flex',
        height: 'fit-content',
        // maxHeight: '40%',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      {/* <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', transform: 'translateY(-100%)' }}>Overlay</div> */}
      {svgDimensions && selectedTool && canvasRef && showContextToolBar && ["Split", "Grid"].includes(viewMode) &&
        <ContextToolBar
          selectedTool={selectedTool}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
          selectedNomexColor={selectedNomexColor}
          setSelectedNomexColor={setSelectedNomexColor}
          selectedFabric={selectedFabric}
          viewMode={viewMode}
          canvasRef={canvasRef}
          onClose={() => {
            setShowContextToolBar(false);
            // if (["Paravent", "Graphic", "Text", "Scene"].includes(selectedTool)) {
            //   setSelectedTool(null);
            // }
          }}
          showModal={showImageUploader}
          setShowModal={setShowImageUploader}
        />}
      {<ImageUploader
        show={showImageUploader}
        onHide={handleCloseModal}
        canvasRef={canvasRef}
        selectedTool={selectedTool}
      />}
      {svgDimensions &&
        <Canvas id="canvas-container"
          svgContent={svgContent}
          // dimensions={canvasDimensions}
          svgDimensions={svgDimensions}
          selectedTool={selectedTool}
          selectedColor={selectedColor}
          selectedNomexColor={selectedNomexColor}
          canvasState={canvasState}
          setCanvasState={setCanvasState}
          setEnvelopeDimension={setEnvelopeDimension}
          defaultColor={defaultColor}
          defaultNomexColor={defaultNomexColor}
          history={history}
          setHistory={setHistory}
          redoStack={redoStack}
          setRedoStack={setRedoStack}
          containerRef={containerRef}
          viewMode={viewMode}
          paraventSize={paraventSize}
          ref={canvasRef}
          style={{ position: 'absolute', top: 0, left: 0, border: '1px solid black', paddingTop: '1px', paddingRight: '1px' }} />}
    </div>
  );
}

export default CanvasContainer;